import Progress from './Progress';
import { useTypedSelector } from '../../hook/useTypedSelector';
import { ScreenEnums } from '../../store/redux-enums';
import { memo, useEffect } from "react";
import ApplicationForm from './Steps/ApplicationForm';
import Benefits from './Steps/Benefits';
import AboutCompany from './Steps/AboutCompany';
import Requirements from './Steps/Requirements';
import JobDetails from './Steps/JobDetails';
import JobBoards from './Steps/JobBoards';
import DraftPopup from './Popups/DraftPopup';
import PublishPopup from './Popups/PublishPopup';
import ApprovalPopup from './Popups/ApprovalPopup';
import CancelPopup from './Popups/CancelPopup';
import SaveTemplatePopup from './Popups/SaveTemplatePopup';
import JobHead from './JobHead';
import ChangeSaved from './Popups/ChangeSaved';
import EmptyJob from "../EmptyJob";
import { getEnv } from "@urecruits/api";
import { changeWorkflowsList } from "../../store/action-creators";
import { fetchData } from "../../hook/fetchData";
import { store } from "../../store";
import { useNavigate } from 'react-router-dom';
import { useSubscription, WarningBoxComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';


const {API_RECRUITMENT} = getEnv()

const progressWidthArr = [
  {
    id: 1,
    value: '12%'
  },
  {
    id: 2,
    value: '31%'
  },
  {
    id: 3,
    value: '48%'
  },
  {
    id: 4,
    value: '65%'
  },
  {
    id: 5,
    value: '88%'
  },
  {
    id: 6,
    value: '100%'
  },
]

const InnerScreen = (props) => {
  //subscription information
  const { subscriptionData } = useSubscription()
  const screen = useTypedSelector(state => state.screen)

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/workflow/company-job`).then(res=>store.dispatch(changeWorkflowsList(res.map(item=>{return{label:item.title,value:item.id}}))))
    document.addEventListener('visibilitychange',visibilityChangeWorkflows)
    return ()=> document.removeEventListener('visibilitychange',visibilityChangeWorkflows)
  }, []);

  const visibilityChangeWorkflows=()=>{
    if(document.visibilityState==='visible'){
      fetchData(`${API_RECRUITMENT}/api/workflow/company-job`).then(res=>store.dispatch(changeWorkflowsList(res.map(item=>{return{label:item.title,value:item.id}}))))
    }
  }
  const navigate=useNavigate()

    // useEffect(() => {
    //   const isFirstLoad = sessionStorage.getItem('isFirstLoad') === null || sessionStorage.getItem('isFirstLoad') === "";
    //   if (isFirstLoad) {
    //     sessionStorage.setItem('isFirstLoad', 'true');
    //   } else {
    //     sessionStorage.setItem('isFirstLoad', "");
    //     navigate("/recruitment/jobs")
    //   }
    //   return () => {
    //     store.dispatch(changeCurrenScreen(ScreenEnums.JOB_DETAILS))  
    //     store.dispatch(reset_screen_details())
    //     sessionStorage.setItem('isFirstLoad', "");   
    //    };
    // }, []);

  return (
    <>
      {
       !props.loader && !props.checkEmpty && (
          <section className="job">
            <JobHead/>
            <Progress
              array={[
                ScreenEnums.JOB_DETAILS,
                ScreenEnums.REQUIREMENTS,
                ScreenEnums.BENEFITS,
                ScreenEnums.ABOUT_COMPANY,
                ScreenEnums.JOB_BOARDS,
                ScreenEnums.APPLICATION
              ]}
              progressWidthArr={progressWidthArr}
              currentScreen={screen.currentScreen}
            />
            {subscriptionData?.status !== 'trialing' && <WarningBoxComponent message='Alert: Exceeding the job limit will incur add-on charges based on your package.' className='job__warning'/>}
            <div className="job__steps">
              {
                screen.currentScreen === ScreenEnums.JOB_DETAILS && <JobDetails/>
              }
              {
                screen.currentScreen === ScreenEnums.REQUIREMENTS && <Requirements/>
              }
              {
                screen.currentScreen === ScreenEnums.BENEFITS && <Benefits/>
              }
              {
                screen.currentScreen === ScreenEnums.ABOUT_COMPANY && <AboutCompany/>
              }
              {
                screen.currentScreen === ScreenEnums.JOB_BOARDS && <JobBoards/>
              }
              {
                screen.currentScreen === ScreenEnums.APPLICATION && <ApplicationForm/>
              }
              {
                screen.draftPopup && <DraftPopup/>
              }
              {
                screen.publishPopup && <PublishPopup/>
              }
              {
                screen.approvalPopup && <ApprovalPopup/>
              }
              {
                screen.cancelPopup && <CancelPopup/>
              }
              {
                screen.savedPopup && <ChangeSaved/>
              }
              {
                screen.saveTemplatePopup && <SaveTemplatePopup/>
              }
            </div>
          </section>
        )
      }
      {
        props.checkEmpty &&(
          <EmptyJob/>
        )
      }
    </>
  )
}

export default memo(InnerScreen)