export enum ManageAssignmentTabs{
	jobTitle='Job Title',
	candidates='Candidates',
	assessmentType='Assessment Type',
	Actions='Actions',
	assignmentName='Job Title/Assignment Name'
}

export enum ManageAssignmentFilterTabs{
	assessmentType='Assessment Type',
	jobId='Job Title',
}