import { useState } from 'react';
import { store } from '../../../store';
import { changeApprovalPopup, changePublishPopup, reset_screen_details } from "../../../store/action-creators";
import { Link } from '@reach/router';
import { useTypedSelector } from '../../../hook/useTypedSelector';
import axios from 'axios';
import { useCurrentPopupData } from '../../../hook/useCurrentPopupData';
import { getEnv } from "@urecruits/api";
import { useParams } from "react-router-dom";

const messageIcon = require('../../../image/icon/green_message_ic.svg')
const successImage = require('../../../image/icon/success_image.svg')

const PublishPopup = () => {
  const location = useParams();
  const [jobId, setJobId] = useState()
  const token: string = localStorage.getItem('token')
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  const initData = useTypedSelector(state => state.screen.jobDetails)
  const actionState = useTypedSelector(state => state.screen.actionState)
  const data = useCurrentPopupData('publish')

  const [success, setSuccess] = useState(false)
  const [errorNotification, setErrorNotification] = useState('')
  const {API_RECRUITMENT} = getEnv()

  //TODO: need to create reuse func for publish/approval, draft
  const onPublish = () => {
    actionState === 'Edit Job' && (
      axios.patch(`${API_RECRUITMENT}/api/job`, Object.assign(data, {id: parseInt(location.value)}), config).then((res) => {
        if (res.status == 200) {
          setJobId(res.data.id);
          setSuccess(true);
        }
      })
    )

    actionState === 'Create a New Job' && (
      axios.post(`${API_RECRUITMENT}/api/job`,data, config).then((res) => {
        if (res.status == 201){
          axios.post(`${API_RECRUITMENT}/api/subscription/add-ons`,{addonName:'job',type:'increase'},config).then(data=>{
            setJobId(res.data.id)
            setSuccess(true)
          })
        }
      })
    )

  }

  return (
    <div className="popup">
      {
        !success ?
          <div className="popup__step">
            <div className="popup__head">
              <p className="popup__head__headline">
                Publish the Job
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__head__close"
                onClick={() => store.dispatch(changePublishPopup(false))}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <p className="popup__body__text">
                Would you like to publish your work on <a href="https://urecruits.com/"
                                                          target="_blank">uRecruits</a> immediately or send it for
                approval first?
              </p>
            </div>
            <div className="popup__bottom end">
              <button
                className="popup__cancel"
                onClick={() => store.dispatch(changePublishPopup(false))}
              >
                Cancel
              </button>
              <button
                className="popup__approval"
                onClick={() => {
                  store.dispatch(changePublishPopup(false))
                  store.dispatch(changeApprovalPopup(true))
                }}
              >
                <img src={messageIcon} alt="message icon" className="popup__approval__icon"/>
                <p className="popup__approval__text">
                  Send for Approval
                </p>
              </button>
              <button
                className="popup__bottom__publish button--filled"
                onClick={onPublish}
              >
                Publish
              </button>
              {
                errorNotification.length > 0 && (
                  <p className="error-message">{errorNotification}</p>
                )
              }
            </div>
          </div>
          :
          <div className="popup__step">
            <div className="popup__head">
              <p className="popup__head__headline">
                Published
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="popup__head__close"
                onClick={() => store.dispatch(changePublishPopup(false))}
              >
                <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="popup__body">
              <img src={successImage} alt="" className="popup__body__image"/>
              <p className="popup__body__text">
                The job {initData.jobTitle} have been successfully published on <a href="https://urecruits.com/"
                                                                                   target="_blank">uRecruits</a>.
                Click <Link to={`/job/${jobId}`}>Link</Link> to see it.
              </p>
            </div>
            <div className="popup__bottom center">
              <Link
                to={'/recruitment/jobs'}
                className="popup__bottom__publish button--filled"
                onClick={() => {
                  store.dispatch(changePublishPopup(false))
                  store.dispatch(reset_screen_details())
                }}
              >
                Got it!
              </Link>
            </div>
          </div>
      }
    </div>
  )
}

export default PublishPopup