import '../styles/main.scss'
import { store } from '../store'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { CreateJob, EditJob } from '../components/CreateJob/setDataHoc'
import ApplyInner from '../components/ApplyJob/ApplyInner'
import ViewJobScreen from '../screens/ViewJobScreen'
import { useHasPermission, permissionRoutesType, UnAuthorizePageComponent, NotFoundPageComponent, useSubscription, UpgradeSubscriptionComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'


const candidateRoutes: Array<permissionRoutesType> = [
  {
    path: 'job/:value/apply',
    element: <ApplyInner />,
    module: 'candidate',
    permissions: '',
  },
];
const companyRoutes: Array<permissionRoutesType> = [
  {
    path: 'job/create',
    element: <CreateJob />,
    module: 'job-post',
    permissions: 'add',
    planPackages:['recruitment','fullcycle']
  },
  {
    path: 'job/:value/edit',
    element: <EditJob />,
    module: 'job-post',
    permissions: 'edit',
    planPackages: ['recruitment', 'fullcycle']
  },
];
const candidateCompanyRoutes: Array<permissionRoutesType> = [
  {
    path: 'job/:value',
    element: <ViewJobScreen />,
    module: ['candidate', 'recruiter'],
    permissions: '',
  },
]

export default function Root(props) {
  const { onAuthorizedRoutes, companyId } = useHasPermission()
  const { isLoadingSubscription, hasCompanySubscriptionElement, checkCurrentPackageType } = companyId && useSubscription();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {!companyId && candidateRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          })}

          {companyId && companyRoutes.map(route => {
            const isAuthorized = route.planPackages
              ? checkCurrentPackageType(route.planPackages) &&
              onAuthorizedRoutes(route.module, route.permissions, route?.option || "")
              : onAuthorizedRoutes(route.module, route.permissions, route?.option || "");

            const routeElement = isAuthorized ? route.element : route.planPackages ? <UpgradeSubscriptionComponent/> : <UnAuthorizePageComponent />;

            return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(routeElement)} />;
          })}

          {!companyId ? candidateCompanyRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          }) :
            candidateCompanyRoutes.map(route => {
              return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent /> )} />
            })
          }

          <Route path="job/*" element={companyId && isLoadingSubscription ? <h1>Loading...</h1> :<NotFoundPageComponent />} />
        </Routes>
      </BrowserRouter>
    </Provider>

  )
}
