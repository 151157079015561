import { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import { WorkFlowErrorsEnum } from "../../../enums/workflow/WorkFlowEnums";
import fetchData from "../../../hook/http";
import InfoIc from "../../../image/icon/info_ic.svg";
import plusIc from "../../../image/icon/plus_ic.svg";
import { navigate } from "@reach/router";
import { store, useTypedSelector } from "../../../store";
import {
  setDeadlineDomains,
  setDomainAssessment,
  setErrorsDomains,
} from "../../../store/reducers/positionWorkflowPopup";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import SmallLoader from "../../Global/SmallLoader";
import { getEnv } from "@urecruits/api";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_ASSESSMENT}=getEnv()

const FunctionalAssessment = () => {
  const domains = useTypedSelector(
    (state) => state.position_workflow_popup.domains
  );
  const reviewers = useTypedSelector(
    (state) => state.position_workflow_popup.reviewersList
  );
  const [getAssessment, setAssessment] = useState([]);
  const [loader,setLoader] = useState(false)
  const [deadlineList, setDeadlineList] = useState([]);

  useEffect(() => {
    setLoader(true)
    fetchData(`${API_ASSESSMENT}/api/domain-questions/all`).then(
      (res) => {
        const formattedData = res.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        setAssessment(formattedData);
        setLoader(false)
      }
    ).catch(er=>setLoader(false));

    fetchData(`${API_ASSESSMENT}/api/assignment/deadline`).then(
      (res) => {
        const formattedData = res.map((item) => ({
          value: parseInt(item.time.split(" ")),
          label: item.time,
        }));

        formattedData.sort((a, b) => a.value - b.value);
        setDeadlineList(formattedData);
      }
    );
  }, []);

  return (
    <div className="popup-inside-modal">
      {getAssessment.length > 0 ? (
        <div>
          <div className="popup-inside-modal__item">
            <div className="popup-inside-modal__item nomb">
              <label
                className="recruitment__popup-item__label"
                htmlFor="List of Assessments"
              >
                List of Assessments<span> *</span>
              </label>
              <ReactSelect
                options={getAssessment && getAssessment}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  store.dispatch(setDomainAssessment(item.id));
                  store.dispatch(
                    setErrorsDomains(
                      domains.errors.filter(
                        (x) => x !== WorkFlowErrorsEnum.assessments
                      )
                    )
                  );
                }}
                value={getAssessment.find((x) => x.id === domains.assessments)}
                placeholder="Choose assessment from database"
                styles={selectCustomStyle}
                id="assessmentDomain"
                instanceId="assessmentDomain"
              />
              {domains.errors.includes(WorkFlowErrorsEnum.assessments) && (
                <p className="recruitment__popup-item__error-message">
                  This is required field
                </p>
              )}
            </div>
            <AuthGuard module='assessment' permission='add'>
            <p className="popup-inside-modal__item__text">or</p>
            <div
              className="popup-inside-modal__more"
              onClick={() => {
                navigate("/manage-domain");
                localStorage.setItem("prevRoute","/recruitment/position-workflow/create")
              }}
            >
              <img
                src={plusIc}
                alt="plust icon"
                className="popup-inside-modal__more--icon"
              />
              <p className="popup-inside-modal__more--text">
                Create new assessment
              </p>
            </div>
            </AuthGuard>
          </div>
          <div className="popup-inside-modal__item">
            <label
              className="recruitment__popup-item__label"
              htmlFor="List of Assessments"
              style={{ display: "flex" }}
            >
              Deadline<span> *</span>
              <div className="helper-icon" style={{ marginRight: "8px" }}>
                <img src={InfoIc} alt="info icon" />
                <div className="helper-icon__info">
                  <div
                    className="helper-icon__info--text"
                    style={{ fontWeight: 400 }}
                  >
                    No. of Days to complete assignment after candidate has
                    applied to the job
                  </div>
                  <div className="helper-icon__info--square"></div>
                </div>
              </div>
            </label>
            <ReactSelect
              options={deadlineList}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                store.dispatch(setDeadlineDomains(item.value));
                store.dispatch(
                  setErrorsDomains(
                    domains.errors.filter(
                      (x) => x !== WorkFlowErrorsEnum.deadline
                    )
                  )
                );
              }}
              value={deadlineList.find((x) => x.value === domains.deadline)}
              placeholder="Select deadline"
              styles={selectCustomStyle}
              id="deadline"
              instanceId="deadline"
            />
            {domains.errors.includes(WorkFlowErrorsEnum.deadline) && (
              <p className="recruitment__popup-item__error-message">
                This is required field
              </p>
            )}
          </div>
        </div>
      ) :  loader ?(
        <div className="popup-inside-modal__item">
          <div style={{ margin: "auto" }}>
            <SmallLoader />
          </div>
        </div>
      )
          : <AuthGuard module='assessment' permission='add'>
            <p className="popup-inside-modal__item__text empty">No assessment yet.</p>
            <div
              className="popup-inside-modal__more"
              onClick={() => {
                navigate("/manage-domain");
                localStorage.setItem("prevRoute", "/recruitment/position-workflow/create")
              }}
            >
              <img
                src={plusIc}
                alt="plust icon"
                className="popup-inside-modal__more--icon"
              />
              <p className="popup-inside-modal__more--text">
                Create new assessment
              </p>
            </div>
      </AuthGuard>
    }
    </div>
  );
};

export default FunctionalAssessment;
