import plusIc from "../../../image/icon/plus_ic.svg";
import InfoIc from "../../../image/icon/info_ic.svg";
import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { navigate } from "@reach/router";
import { store, useTypedSelector } from "../../../store";
import {
  setDeadlineTakeHomeTask,
  setDurationsLiveCoding,
  setDurationsTakeHomeTask,
  setErrorsLiveCoding, setErrorsTakeHomeTask,
  setQuestionListLiveCoding,
  setQuestionListTakeHomeTask,
  setQuestionTypeLivecoding,
  setQuestionTypeTakeHomeTask
} from "../../../store/reducers/positionWorkflowPopup";
import { WorkFlowErrorsEnum } from "../../../enums/workflow/WorkFlowEnums";
import { useEffect, useState } from "react";
import fetchData from "../../../hook/http";
import SmallLoader from "../../Global/SmallLoader";
import { getEnv } from "@urecruits/api";
import { CodingAssessmentType } from "../../../enums";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv()

const TechnicalAssessment = ({ questionType }) => {

  const techAssessment = useTypedSelector(
    (state) => questionType === CodingAssessmentType.LIVE_TASK ? state.position_workflow_popup.liveCoding : state.position_workflow_popup.takeHomeTask
  );
  const [createAssessment, setCreateAssessment] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [deadlineError, setDeadlineError] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);
  const [durationTime, setDurationTime] = useState([]);
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (questionType) {
      questionType === CodingAssessmentType.LIVE_TASK && store.dispatch(setQuestionTypeLivecoding(questionType));
      questionType === CodingAssessmentType.TAKE_HOME_TASK && store.dispatch(setQuestionTypeTakeHomeTask(questionType));
      fetchData(
        `${API_ASSESSMENT}/api/coding-assessment/search?type=${questionType}`
      ).then((res) => {
        const formattedData = res.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        setAssessmentList(formattedData);
        if (questionType === "live-task") {
          setDeadlineError(false);
        }
        setLoader(false)
      }).catch(er => setLoader(false));
    } else {
      setAssessmentList([]);
    }
  }, [questionType]);
  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/assignment/time-duration`
    ).then((res) => {
      const formattedData = res.map((item) => ({
        id: item.id,
        label: item.timeDuration,
        questionType: questionType,
      }));

      formattedData.sort((a, b) => {
        const firstValue = parseInt(a.label.split(" ")[0]);
        const secondValue = parseInt(b.label.split(" ")[0]);
        return firstValue - secondValue;
      });

      setDurationTime(formattedData);
    });
  }, []);

  useEffect(() => {
    questionType === CodingAssessmentType.TAKE_HOME_TASK && store.dispatch(setDeadlineTakeHomeTask(techAssessment.deadline || deadline));
  }, [deadline]);

  useEffect(() => {
    if (createAssessment) {
      if (questionType === CodingAssessmentType.LIVE_TASK) {
        navigate("/coding-assessments/live-task");
      } else {
        navigate("/coding-assessments/take-home-task");
      }
    }
  }, [createAssessment]);

  return (
    <>
      <div className="popup-inside-modal">
        {loader ? (
          <div className="popup-inside-modal__item">
            <div style={{ margin: "auto" }}>
              <SmallLoader />
            </div>
          </div>
        ) : assessmentList?.length > 0 ? (
          <div className="popup-inside-modal__item">
            <div className="popup-inside-modal__item nomb">
              <label
                className="recruitment__popup-item__label"
                htmlFor="List of Questions"
              >
                List of Assessments<span> *</span>
              </label>
              <ReactSelect
                options={assessmentList && assessmentList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  if (questionType === CodingAssessmentType.LIVE_TASK) {
                    store.dispatch(setQuestionListLiveCoding(item.id))
                    store.dispatch(
                      setErrorsLiveCoding(
                        techAssessment.errors.filter(
                          (x) => x !== WorkFlowErrorsEnum.questionList
                        )
                      )
                    );
                  } else {
                    store.dispatch(setQuestionListTakeHomeTask(item.id))
                    store.dispatch(
                      setErrorsTakeHomeTask(
                        techAssessment.errors.filter(
                          (x) => x !== WorkFlowErrorsEnum.questionList
                        )
                      )
                    );
                  }
                }}
                value={
                  techAssessment.questionList
                    ? assessmentList.find(
                      (x) => x.id === techAssessment.questionList
                    )
                    : ""
                }
                placeholder="Choose question from database"
                styles={selectCustomStyle}
                id="List of Questions"
                instanceId="List of Questions"
              />
              {techAssessment.errors.includes(
                WorkFlowErrorsEnum.questionList
              ) && (
                  <p className="recruitment__popup-item__error-message">
                    This is required field
                  </p>
                )}
            </div>
            <AuthGuard module='assessment' permission='add'>
              <p className="popup-inside-modal__item__text">or</p>
              <div
                className="popup-inside-modal__more"
                onClick={() => {
                  questionType && setCreateAssessment(true)
                  localStorage.setItem("prevRoute", "/recruitment/position-workflow/create")
                }
                }
              >
                <img
                  src={plusIc}
                  alt="plust icon"
                  className="popup-inside-modal__more--icon"
                />
                <p className="popup-inside-modal__more--text">
                  Create new assessment
                </p>
              </div>
            </AuthGuard>
          </div>
          ) : <AuthGuard module='assessment' permission='add'>
            <p className="popup-inside-modal__item__text empty">No assessments yet.</p>
            <div
              className="popup-inside-modal__more"
              onClick={() => {
                questionType && setCreateAssessment(true)
                localStorage.setItem("prevRoute", "/recruitment/position-workflow/create")
              }
              }
            >
              <img
                src={plusIc}
                alt="plust icon"
                className="popup-inside-modal__more--icon"
              />
              <p className="popup-inside-modal__more--text">
                Create new assessment
              </p>
            </div>
          </AuthGuard>}
        {questionType === CodingAssessmentType.TAKE_HOME_TASK && (
          <div className="popup-inside-modal__item">
            <label
              className="recruitment__popup-item__label"
              htmlFor="List of Assessments"
              style={{ display: "flex" }}
            >
              Deadline<span> *</span>
              <div className="helper-icon" style={{ marginRight: "8px" }}>
                <img src={InfoIc} alt="info icon" />
                <div className="helper-icon__info">
                  <div
                    className="helper-icon__info--text"
                    style={{ fontWeight: 400 }}
                  >
                    No of Days to complete assignment after candidate has
                    applied to the job
                  </div>
                  <div className="helper-icon__info--square" />
                </div>
              </div>
            </label>
            <input
              autoComplete="off"
              className="recruitment__popup-item__input"
              type="number"
              id="deadline"
              value={techAssessment.deadline || deadline || ""}
              placeholder="Enter deadline in days"
              onChange={(e) => {
                setDeadline(parseInt(e.target.value));
                setDeadlineError(false);
              }}
            />

            {deadlineError === true && (
              <p className="recruitment__popup-item__error-message">
                This is required field
              </p>
            )}
          </div>
        )}
        <div className="popup-inside-modal__item">
          <label
            className="recruitment__popup-item__label"
            htmlFor="Time Duration"
            style={{ display: "flex" }}
          >
            Time Duration<span> *</span>
            <div className="helper-icon" style={{ marginRight: "8px" }}>
              <img src={InfoIc} alt="info icon" />
              <div className="helper-icon__info">
                <div
                  className="helper-icon__info--text"
                  style={{ fontWeight: 400 }}
                >
                  How much time you want to give to the candidate to solve a
                  problem or take a test
                </div>
                <div className="helper-icon__info--square"></div>
              </div>
            </div>
          </label>
          <ReactSelect
            options={durationTime}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            onChange={(item: any) => {
              if (questionType === CodingAssessmentType.LIVE_TASK) {
                store.dispatch(setDurationsLiveCoding(item.id));
                store.dispatch(setQuestionTypeLivecoding(item.questionType))
                store.dispatch(
                  setErrorsLiveCoding(
                    techAssessment.errors.filter(
                      (x) => x !== WorkFlowErrorsEnum.timeDuration
                    )
                  )
                );
              } else {
                store.dispatch(setDurationsTakeHomeTask(item.id));
                store.dispatch(setQuestionTypeTakeHomeTask(item.questionType))
                store.dispatch(
                  setErrorsTakeHomeTask(
                    techAssessment.errors.filter(
                      (x) => x !== WorkFlowErrorsEnum.timeDuration
                    )
                  )
                );
              }
            }}
            value={durationTime.find(
              (x) => x.id === techAssessment.durations && x.questionType === techAssessment.questionType
            )}
            placeholder="Choose the time duration"
            styles={selectCustomStyle}
            id="Time Duration"
            instanceId="Time Duration"
          />
          {techAssessment.errors.includes(
            WorkFlowErrorsEnum.timeDuration
          ) && (
              <p className="recruitment__popup-item__error-message">
                This is required field
              </p>
            )}
        </div>
      </div>
    </>
  );
};
export default TechnicalAssessment;
