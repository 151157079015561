import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  addActiveOptions,
  changeOrderActiveOptions, removeInActiveOptions,
  resetPositionWorkflow, setErrorsDomains,
  setErrorsLiveCoding,
  setErrorsTakeHomeTask,
  setReviewersPWPopup,
  setTitle as setTitleName
} from "../../../store/reducers/positionWorkflowPopup";
import { memo, useEffect, useState } from "react";
import {
  restrictToParentElement,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import DNDWorkflowsWrap from "./DNDWorkflowsWrap";
import { store, useTypedSelector } from "../../../store";
import { components } from "react-select";
import DNDItem from "./DNDItem";
import { useNavigate, useParams } from "react-router-dom";
import { WorkFlowEnums, WorkFlowErrorsEnum } from "../../../enums/workflow/WorkFlowEnums";
import fetchData, { patchData, postData } from "../../../hook/http";
import { getEnv } from "@urecruits/api";
import { IPositionWorkflowPopup } from "../../../types/redux/position-workflow";
// import { setItemsPositionWorkflow } from "../../../screen/PositionWorkflowScreen";
import { CodingAssessmentType } from "../../../enums";
import { useHasPermission, modulesType, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { setCreatePopupWorkflow, setEditPopupWorkflow } from "../../../store/reducers/positionWorkFlowReducer";
import { planPackageType } from "@ucrecruits/globalstyle/context/types";
// import { modulesType } from "@ucrecruits/globalstyle";

const getTableWorkflow = (state) => state.position_workflow;
const { API_RECRUITMENT } = getEnv();

interface IPopup {
  navigateLink?: string;
  titleTop: string;
  children?: any;
  fromJob?:any;
  returnDefault: () => void;
  create?:boolean,
  edit?:boolean,
  id?:number,
  setItemsPositionWorkflow?:(a,b,c)=>void
}

export const PWPopup = ({ titleTop, returnDefault, navigateLink, fromJob, setItemsPositionWorkflow,...props }: IPopup) => {

  const {checkUserPermission} = useHasPermission()
  const {checkCurrentPackageType} = useSubscription()
  const table_workflows = useTypedSelector(getTableWorkflow);
  const activeOptions = useTypedSelector(
    (state) => state.position_workflow_popup.activeOptions
  );
  const liveCoding = useTypedSelector(
    (state) => state.position_workflow_popup.liveCoding
  );
  const takehomeTask = useTypedSelector(
    (state) => state.position_workflow_popup.takeHomeTask
  );
  const domains = useTypedSelector(
    (state) => state.position_workflow_popup.domains
  );
  const inActiveOptions = useTypedSelector(
    (state) => state.position_workflow_popup.inActiveOptions
  );
  const allData = useTypedSelector((state) => state.position_workflow_popup);

  const navigate = useNavigate();
  const params = useParams();

  const [scrollActive, setScrollActive] = useState(false);

  //errors
  const [titleError, setTitleError] = useState(false);
  const [activeId, setActiveId] = useState<any>(-1);
  const [timeSlotsError, setTimeSlotsError] = useState(false);
  const [emptyWorkflowError, setEmptyWorkflowError] = useState(false);

  const [somethingWentWrong, setSomethingWentWrong] = useState("");
  const [successSend, setSuccessSend] = useState("");

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const filterWorkflow=(array:any[])=>{
    const permissionModule: { module: modulesType, package: planPackageType }[] = [{ module: 'assessment', package: 'assessment' }, { module: 'background', package: 'recruitment' }, { module: 'offer',package: 'recruitment' }, { module: 'drug',package: 'recruitment' }]
    let filteredArr = [...array]
   for(let permission of permissionModule){
     if (!checkUserPermission(permission.module, 'view') || (!checkCurrentPackageType(permission.package) && !checkCurrentPackageType('fullcycle'))){
      if(permission.module  === 'assessment'){
        filteredArr =  filteredArr.filter(i=>!i.settings)
      }
      if(permission.module === 'background'){
        filteredArr = filteredArr.filter(i=>i.name !== WorkFlowEnums.Background_Screening)
      }
      if(permission.module === 'drug'){
        filteredArr = filteredArr.filter(i => i.name !== WorkFlowEnums.Drug_Screening)
      }
      if(permission.module === 'offer'){
        filteredArr = filteredArr.filter(i=>i.name !== WorkFlowEnums.Conditional_Offer_Letter_Generation)
      }
    }
   }
    return filteredArr || []
  }
  useEffect(() => {
    const func = async () => {
      if(checkUserPermission('team-members','view')){

        const data = await fetchData(
          `${API_RECRUITMENT}/api/company/get-workflow-reviewers`
        );
        store.dispatch(setReviewersPWPopup(data));
      }else{
        store.dispatch(setReviewersPWPopup([]));
      }
    };
    func().then();
  }, []);

  const validation = () => {
    let result = true;
    let domainErrors = domains.errors;
    let liveCodingErrors = liveCoding.errors;
    let takeHomeTaskErrors = takehomeTask.errors;

    if (allData.title === "") {
      setTitleError(true);
      result = false;
    }
    
    if (activeOptions.find((x) => x.id === 2)) {
      if (!domains.assessments) {
        domainErrors = [...domainErrors, WorkFlowErrorsEnum.assessments];
        store.dispatch(setErrorsDomains(domainErrors));
        result = false;
      }
      if (!domains.deadline) {
        domainErrors = [...domainErrors, WorkFlowErrorsEnum.deadline];
        store.dispatch(setErrorsDomains(domainErrors));
        result = false;
      }
    }
    if (activeOptions.length === 0) {
      setEmptyWorkflowError(true);
      result = false;
    }
    if (activeOptions.find((x) => x.id === 3)) {
      if (!liveCoding.questionType) {
        liveCodingErrors = [...liveCodingErrors, WorkFlowErrorsEnum.questionType];
        store.dispatch(setErrorsLiveCoding(liveCodingErrors));
        result = false;
      }
      if (!liveCoding.questionList) {
        liveCodingErrors = [...liveCodingErrors, WorkFlowErrorsEnum.questionList];
        store.dispatch(setErrorsLiveCoding(liveCodingErrors));
        result = false;
      }
      
      if (!liveCoding.durations) {
        liveCodingErrors = [...liveCodingErrors, WorkFlowErrorsEnum.timeDuration];
        store.dispatch(setErrorsLiveCoding(liveCodingErrors));
        result = false;
      }
    }
    if (activeOptions.find((x) => x.id === 4)){
      if (!takehomeTask.deadline) {
        if (liveCoding.questionType === CodingAssessmentType.TAKE_HOME_TASK) {
          liveCodingErrors = [...liveCodingErrors, WorkFlowErrorsEnum.deadline];
          store.dispatch(setErrorsLiveCoding(liveCodingErrors));
          result = false;
        }
      }
      if (!takehomeTask.questionType) {
        takeHomeTaskErrors = [...takeHomeTaskErrors, WorkFlowErrorsEnum.questionType];
        store.dispatch(setErrorsTakeHomeTask(takeHomeTaskErrors));
        result = false;
      }
      if (!takehomeTask.questionList) {
        takeHomeTaskErrors = [...takeHomeTaskErrors, WorkFlowErrorsEnum.questionList];
        store.dispatch(setErrorsTakeHomeTask(takeHomeTaskErrors));
        result = false;
      }

      if (!takehomeTask.durations) {
        takeHomeTaskErrors = [...takeHomeTaskErrors, WorkFlowErrorsEnum.timeDuration];
        store.dispatch(setErrorsTakeHomeTask(takeHomeTaskErrors));
        result = false;
      }
    }
    return result;
  };

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    store.dispatch(
      changeOrderActiveOptions(
        arrayMove(
          activeOptions,
          activeOptions.indexOf(activeOptions.find((x) => x.id === active.id)),
          activeOptions.indexOf(activeOptions.find((x) => x.id === over.id))
        )
      )
    );
  };

  const submit = async () => {
    setTimeSlotsError(false);
    setTitleError(false);
    setEmptyWorkflowError(false);
    store.dispatch(setErrorsDomains([]));
    store.dispatch(setErrorsLiveCoding([]));
    store.dispatch(setErrorsTakeHomeTask([]));
    const data = prepareDataForBackend(allData);
    if (validation()) {
      if (titleTop === "Add New Workflow") {
        postData(`${API_RECRUITMENT}/api/workflow`, data).then(
          () => {
            if (somethingWentWrong !== "") setSomethingWentWrong("");
            setSuccessSend("Workflow successfully added");
            setItemsPositionWorkflow && setItemsPositionWorkflow(
              1,
              table_workflows.pagination.limit,
              table_workflows.filters
            );
            setTimeout(() => {
              if (!fromJob) {
                if (props?.create) {
                  store.dispatch(setCreatePopupWorkflow(false))
                } else {
                  navigate(navigateLink)
                }
              } else {
                fromJob(false)
              }
              setSuccessSend("");
              returnDefault();
              store.dispatch(resetPositionWorkflow())
            }, 1500);
          },
          (err) => {
            setSomethingWentWrong("Something went wrong");
          }
        );
      } else {
        patchData(`${API_RECRUITMENT}/api/workflow`, {
          ...data,
          id: params.id||props?.id,
        }).then(
          () => {
            if (somethingWentWrong !== "") setSomethingWentWrong("");
            setSuccessSend("Workflow successfully changed");
            setItemsPositionWorkflow && setItemsPositionWorkflow(
              1,
              table_workflows.pagination.limit,
              table_workflows.filters
            );
            setTimeout(() => {
              if (!fromJob) {
                if (props?.edit) {
                  store.dispatch(setEditPopupWorkflow({ open: false, id: null }))
                } else {
                  navigate(navigateLink)
                }
              } else {
                fromJob(false)
              }
              setSuccessSend("");
              returnDefault();
            }, 1500);
          },
          (err) => {
            setSomethingWentWrong("Something went wrong");
          }
        );
      }
    }
  };

  useEffect(() => {
    if (activeOptions.length > 0) setEmptyWorkflowError(false);
  }, [activeOptions]);

  return (
    <div className="popup-right-side">
      <div className="popup-right-side__inner">
        <div
          className={
            scrollActive
              ? "popup-right-side__top popup-right-side__top--active"
              : "popup-right-side__top"
          }
        >
          <h5 className="popup-right-side__top__title">{titleTop}</h5>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="popup-right-side__top__close-button"
            onClick={(e) => {
              e.preventDefault();
              
              if(!fromJob){ 
                if (props?.create) {
                  store.dispatch(setCreatePopupWorkflow(false))
                }else if (props?.edit) {
                  store.dispatch(setEditPopupWorkflow({ open: false, id: null }))
                }else{
                  navigate(navigateLink)
                }
              }else{
                fromJob(false)
              }
            }}
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <form
          className="popup-right-side__form"
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <div
            className="popup-right-side__form__inner"
            onScroll={(e: any) => {
              if (e.target.scrollTop > 5 && !scrollActive) {
                setScrollActive(true);
              } else if (e.target.scrollTop <= 5 && scrollActive) {
                setScrollActive(false);
              }
            }}
          >
            <div className="recruitment__popup-item">
              <label className="recruitment__popup-item__label" htmlFor="title">
                Workflow Title<span> *</span>
              </label>
              <input
                className="recruitment__popup-item__input"
                id="title"
                value={allData?.title || ''}
                placeholder="Enter workflow title"
                onChange={(e) => {
                  store.dispatch(setTitleName(e.target.value))
                  setTitleError(false);
                }}
              />
              {titleError && (
                <p className="recruitment__popup-item__error-message">
                  This is required field
                </p>
              )}
            </div>
            <div className="workflow">
              <label className="recruitment__popup-item__label" htmlFor="title">
                Workflow<span> *</span>
              </label>
              <div className="workflow__selected">
                <DndContext
                  collisionDetection={closestCenter}
                  sensors={sensors}
                  onDragOver={onSortEnd}
                  onDragStart={(e) => setActiveId(e.active.id)}
                  onDragEnd={() => setActiveId(-1)}
                  modifiers={[restrictToWindowEdges, restrictToParentElement]}
                >
                  <SortableContext items={filterWorkflow(activeOptions)}>
                    {filterWorkflow(activeOptions).map((item) => {
                      return <DNDWorkflowsWrap item={item} key={item.id} />;
                    })}
                    <DragOverlay>
                      {activeId > -1 ? (
                        <DNDItem
                          item={activeOptions.find((x) => x.id === activeId)}
                          transitionState={true}
                        />
                      ) : (
                        ""
                      )}
                    </DragOverlay>
                  </SortableContext>
                </DndContext>
              </div>
              <div
                className={`workflow__separator ${
                  activeOptions.length === 0 || inActiveOptions.length === 0
                    ? "none"
                    : ""
                }`}
              />
              <div className="workflow__not-selected">
                {filterWorkflow(inActiveOptions).map((item) => {
                  return (
                    <button
                      className="workflow__not-selected__item"
                      type="button"
                      key={item.id}
                      onClick={(e) => {
                        e.preventDefault();
                        store.dispatch(removeInActiveOptions(item));
                        store.dispatch(addActiveOptions(item));
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00005 1.40046V8.00012M8.00005 14.5998V8.00012M8.00005 8.00012H1.40039H14.5997"
                          stroke="#099C73"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {item.name}
                    </button>
                  );
                })}
                {emptyWorkflowError && (
                  <p className="recruitment__popup-item__error-message">
                    Select at least one workflow{" "}
                  </p>
                )}
              </div>
            </div>
            <div className="popup-right-side__buttons">
              {successSend !== "" && (
                <p className="manage-team-popup__form__success-message">
                  {successSend}
                </p>
              )}
              {somethingWentWrong !== "" && (
                <p className="manage-team-popup__form__success-message manage-team-popup__form__success-message--error">
                  {somethingWentWrong}
                </p>
              )}
              {(timeSlotsError ||
                titleError ||
                emptyWorkflowError ||
                domains.errors.length > 0 ||
                liveCoding.errors.length > 0 || 
                takehomeTask.errors.length >0) && (
                <p className="manage-team-popup__form__success-message manage-team-popup__form__success-message--error">
                  You have some required fields.
                </p>
              )}
              <button
                className="popup-right-side__buttons__cancel button--empty"
                type="reset"
                onClick={(e) => {
                  e.preventDefault();
                  if (!fromJob) {
                    if (props?.create) {
                      store.dispatch(setCreatePopupWorkflow(false))
                    } else if (props?.edit) {
                      store.dispatch(setEditPopupWorkflow({ open: false, id: null }))
                    } else {
                      navigate(navigateLink)
                    }
                  } else {
                    fromJob(false)
                  }}}
              >
                Cancel
              </button>
              <button
                /*className={activeButton ? ' button--filled' : ' button--filled button--filled-disable'}*/
                className="button--filled"
                type="submit"
              >
                {titleTop === "Add New Workflow" ? "Add" : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default memo(PWPopup);

const prepareDataForBackend = (data: IPositionWorkflowPopup) => {
  return {
    title: data.title,
    domainId: data.domains.assessments,
    domainReviewer: data.domains.reviewers,
    domainDeadline: data.domains.deadline,
    liveCodingQuestionType: data.liveCoding.questionType,
    liveCodingId: data.liveCoding.questionList,
    liveCodingTime: data.liveCoding.durations,
    takeHomeTaskQuestionType: data.takeHomeTask.questionType,
    takeHomeTaskDeadline: data.takeHomeTask.deadline,
    takeHomeTaskId: data.takeHomeTask.questionList,
    takeHomeTaskTime: data.takeHomeTask.durations,
    workflow: data.activeOptions,
    availableDays: [],
    availableTime: [],
  };
};

const Option = (props: any) => {
  const days = useTypedSelector(
    (state) => state.position_workflow_popup.timeSlots.daysAll
  );
  return (
    <div>
      <components.Option
        {...props}
        className="recruitment__react-select-options"
      >
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label
          htmlFor={props.value}
          className={props.value === "Select all days" && days ? "active" : " "}
        >
          <span />
        </label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  );
};
